<template>
	<b-navbar toggleable="lg" type="dark" variant="dark" class="menu row" fixed="top">
		<a id="top" />
		<b-container>
			<router-link class="w-link logo no-router-link-active" to="/">
				<img src="@/assets/MGLogo.png" height="25px">
			</router-link>
			<router-link class="w-link no-router-link-active" to="/"><b-navbar-brand>Money Guided</b-navbar-brand></router-link>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav class="menu-center">
					<b-nav-item class="w-link" to="/employers">Employers</b-nav-item>
					<b-nav-item class="w-link" to="/features">Features</b-nav-item>
					<b-nav-item class="w-link" to="/guide">Blogs</b-nav-item>
					<b-nav-item class="w-link" to="/about">About</b-nav-item>
					<b-nav-item class="w-link" to="/faq">FAQ's</b-nav-item>
				</b-navbar-nav>
				<b-navbar-nav class="menu-right">
					<b-button pill v-b-modal.contact-modal>Contact Us</b-button>
				</b-navbar-nav>
			</b-collapse>
		</b-container>
	</b-navbar>
</template>

<script>
export default {
 props: {
   menuMode: String
 },
 methods: {
   menuEvent(mode) {
		this.menuMode = mode;
		this.$emit('menuEvent', mode);
   }
 }
}
</script>

<style scoped>
	.router-link-active {
		border-bottom: 2px solid #F7DFD2;
	}
	.no-router-link-active {
		border-bottom: none !important;
	}
	.logo{
		margin-right: 10px !important;
	}
	.container {
		margin-left: 10px;
		margin-right: 10px;
		max-width: 100%;
	}
	.menu {
		background-color: #281C3B !important;
		min-height: 60px;
	}
	.menu img {
		margin-right: 10px;
	}
	.menu .btn {
		background-color: #ffffff !important;
		color:#281C3B !important;
	}
	.btn:focus, .btn.focus, .btn-secondary:focus, .btn-secondary.focus{
		box-shadow: none !important;
	}
	.navbar-brand {
		font-size: 14px !important;
		font-weight: 600;
	}
	.menu-center {
		margin-left:auto;
		margin-right:auto;
	}
	.w-link, .w-link:hover, .w-link:active, .nav-link {
		color: white !important;
		text-decoration: none;
		margin-right: 15px;
		font-size: 13px;
		padding: 0px;
	}
	.coming-soon {
		color: white !important;
		text-decoration: none;
		margin-right: 15px;
		margin-top: 7px;
		font-size: 13px;
		padding: 0px;
		font-weight: 600;
	}
	@media all and (max-width: 991px)
	{
		.coming-soon {
			display:none;
		}
		.menu img {
			margin-right: 34px;
			margin-left: 10px;
		}
		.router-link-active {
			border-bottom: 0px solid #F7DFD2;
		}
		.w-link, .w-link:hover, .w-link:active, .nav-link {
			color: white !important;
			text-decoration: none;
			margin-right: 0px;
			margin-top: 3px;
			margin-bottom: 3px;
			font-size: 13px;
			padding: 0px;
			text-align: center;
		}
		.btn, .menu-right {
			margin-left:auto;
			margin-right:auto;
			margin-top: 5px;
			margin-bottom: 5px;
		}
		.navbar-toggler {
			border: 0px;
			color: white !important;
		}
	}
</style>